$(document).on('turbolinks:load', function() {
  setFileHandlers();

});

function setFileHandlers() {
  $(':file').off();

  $(':file').change(function() {
    var removeButton = '<a class="remove-file ml-2" href="#"><i class="fa fa-times-circle"></i></a>';
    var input = $(this);
    var numFiles = input.get(0).files ? input.get(0).files.length : 1;
    var label = '<div class="file-name" style="display: inline;">' + input.val().replace(/\\/g, '/').replace(/.*\//, '') + ' ' + removeButton + '</div>';

    handleFileChange(this, label);

    removeFileEventHandler(this);
  });
}

function removeFileEventHandler(input) {
  $removeFileButton = $(input).parents('.col-sm-9').find('.remove-file');

  $removeFileButton.click(function(e) {
    e.preventDefault();
    $container = $(input).parents('.col-sm-9');
    $(input).val(null);
    $fileNameDiv = $container.find('.file-name');
    $fileNameDiv.remove();
  });
}

function handleFileChange(input, label) {
  var file_input_label = $(input).parents('.btn-file');
  file_input_label.after(label);
}
