$(document).on('turbolinks:load', function() {
  $.fn.popover.Constructor.Default.whiteList.table = [];
  $.fn.popover.Constructor.Default.whiteList.tr = [];
  $.fn.popover.Constructor.Default.whiteList.td = [];
  $.fn.popover.Constructor.Default.whiteList.th = [];
  $.fn.popover.Constructor.Default.whiteList.div = [];
  $.fn.popover.Constructor.Default.whiteList.tbody = [];
  $.fn.popover.Constructor.Default.whiteList.thead = [];
  $.fn.popover.Constructor.Default.whiteList.button = [];

  $('[data-toggle="popover"]').popover({ html: true }).on("show.bs.popover", function() {
    if ($('#resourcesTable').length > 0) {
      $($(this).data("bs.popover").getTipElement()).css("min-width", "500px");
    }
  });

  $('.popover-dismiss').popover({
    trigger: 'focus'
  });

});
