$(document).on('turbolinks:load', function() {
  if ($('.my-profile-section').length){
    profilePictureEditListener();
  }
  if ($(".view-profile").length){
    viewContactVcardListener();
  }

});

function profilePictureEditListener() {
  $('.profile-picture-edit-link').click(function(e) {
    e.preventDefault();

    $('#profile-picture-spinner-modal').modal('show');

    $.get('/my-profile/edit_profile_picture');
  });
}

function viewContactVcardListener(){
  $("body").on('click', ".view-profile", function(e){
    e.preventDefault();

    $("#profile-view-spinner-modal").modal("show");
    var data = $(this).data();
    $.get("/my-profile/vcard?contact=" + data.contact);
  });
}