$(document).on("turbolinks:load", function() {

  var showSelected = function() {

    var valueSelected = $('.case-type-select').val();

    var topicsMembership = JSON.parse($("#topics-membership").text());
    var topicsData = JSON.parse($("#topics-data-request").text());
    var topicsAccounting = JSON.parse($("#topics-accounting").text());
    var topicsTechnicalSupport = JSON.parse($("#topics-technical-support").text());
    var topicsStudentServices = JSON.parse($("#topics-student-services").text());
    var topicsFellowships = JSON.parse($("#topics-fellowships").text());
    var topicsMeetings = JSON.parse($("#topics-meetings").text());
    var topicsExternalRelations = JSON.parse($("#topics-external-relations").text());
    var topicsOther = JSON.parse($("#topics-other").text());


    var $select = $("#support_new_form_topic_area");

    $select.empty();
    if (valueSelected === 'Membership or Network Questions') {
      $select.append($("<option>-- Select One --</option>"));
      $.each(topicsMembership, function(index, value) {
        var newOption = $("<option></option>")
          .attr("value", value).text(value);
        $select.append(newOption);
      });
    } else if (valueSelected === 'Data Request') {
      $select.append($("<option>-- Select One --</option>"));
      $.each(topicsData, function(index, value) {
        var newOption = $("<option></option>")
          .attr("value", value).text(value);
        $select.append(newOption);
      });
    } else if (valueSelected === 'Accounting') {
      $select.append($("<option>-- Select One --</option>"));
      $.each(topicsAccounting, function(index, value) {
        var newOption = $("<option></option>")
          .attr("value", value).text(value);
        $select.append(newOption);
      });
    } else if (valueSelected === 'Technical Support') {
      $select.append($("<option>-- Select One --</option>"));
      $.each(topicsTechnicalSupport, function(index, value) {
        var newOption = $("<option></option>")
          .attr("value", value).text(value);
        $select.append(newOption);
      });
    } else if (valueSelected === 'Student Services') {
      $select.append($("<option>-- Select One --</option>"));
      $.each(topicsStudentServices, function(index, value) {
        var newOption = $("<option></option>")
          .attr("value", value).text(value);
        $select.append(newOption);
      });
    } else if (valueSelected === 'Fellowships & Internships') {
      $select.append($("<option>-- Select One --</option>"));
      $.each(topicsFellowships, function(index, value) {
        var newOption = $("<option></option>")
          .attr("value", value).text(value);
        $select.append(newOption);
      });
    } else if (valueSelected === 'Meetings & Events') {
      $select.append($("<option>-- Select One --</option>"));
      $.each(topicsMeetings, function(index, value) {
        var newOption = $("<option></option>")
          .attr("value", value).text(value);
        $select.append(newOption);
      });
    } else if (valueSelected === 'External Relations') {
      $select.append($("<option>-- Select One --</option>"));
     $.each(topicsExternalRelations, function(index, value) {
       var newOption = $("<option></option>")
         .attr("value", value).text(value);
       $select.append(newOption);
     });
   } else if (valueSelected === 'Other') {
     // $select.append($("<option>-- Select One --</option>")); while only one option, make default
    $.each(topicsOther, function(index, value) {
      var newOption = $("<option></option>")
        .attr("value", value).text(value);
      $select.append(newOption);
    });
  }
    // pre-populate from link
  var url = new URL(document.location);

  // Get query parameters object
  var params = url.searchParams;

  // Get value of topic area
  if (params.get("topic_area")) {

    var topic = params.get("topic_area");

    var topic_titleized = makeTitle(topic);

  // Set it as the dropdown value
    $select.val(topic_titleized);
  }
};

  if ($("#support-new-form").length) {
    showSelected();

    $('.case-type-select').change(function() {
      showSelected();
    });
  }

  function makeTitle(slug) {
    var preps = ['and', 'a', 'of', 'but', 'from'];
    return $.map(slug.split(' '), function(k, v) {
      return $.inArray(k.match(/\w*/g)[0], preps) >= 0 ? k : k[0].toUpperCase() + k.slice(1);
    }).join(' ');
  }

});
