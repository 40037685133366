$(document).on("turbolinks:load", function () {
  if ($(".governance-para-wrapper").length) {
    primaryRepresetatives();
    boardOfDirectors();
    permanentCommittees();
    ambassadors();
  }
});

function boardOfDirectors() {
  $(".board-of-directors").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-0").show();
  });
}

function primaryRepresetatives() {
  $(".primary-represetatives").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-1").show();
  });
}

function permanentCommittees() {
  $(".advisory-committees").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-2").show();
  });
}

function ambassadors() {
  $(".ambassadors").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-3").show();
  });
}
