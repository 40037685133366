$.expr[':'].textEquals = function(el, i, m) {
      var searchText = m[3];
      var match = $(el).text().trim().match("^" + searchText + "$");
      return match && match.length > 0;
};

$(document).on('turbolinks:load', function() {

  addCountrySelectListener();

  if ($(".country-select")) {
    var countrySelects = $(".country-select");
    countrySelects.each(function () {
      populateCorrectStates(this);
    });

  }

});

function addCountrySelectListener() {
  $(".country-select").change(function() {
    populateCorrectStates(this);
  });
}


function populateCorrectStates(country) {
  // from selected country --> .col-sm-9 --> .form-group --> next .form-group (x3) --> find state-select
  var $el = $(country).parent().parent().next().next().find(".state-select");
  var countryName = $(country).val();
  var stateSelected = $el.val();


  if ($el.length > 0) {

    $el.empty();

    $.getJSON("/states", { country_name: countryName }, function(data) {

      if (data !== null) {
        if ($el[0].hasAttribute('disabled')) {
          $el.removeAttr('disabled');
        }

        $.each(data, function(key,value) {
          // console.log(value);
          var newOption = $("<option></option>")
          .attr("value", value).text(value);

          $el.append(newOption);
        });

        if (stateSelected) {
          $el.find("option:textEquals('"+stateSelected+"')").prop('selected', true);
        }

      } else {

        $el.attr('disabled', 'disabled');

      }
    });
  }


}


function populateCeCorrectStates(country) {
  // from selected country --> .col-sm-9 --> .form-group --> next .form-group (x3) --> find state-select
  var $el = $(country).parent().parent().next().next().find(".state-select");
  var countryName = $(country).val();
  var stateSelected = $el.val();


  if ($el.length > 0) {

    $el.empty();

    $.getJSON("/states", { country_name: countryName }, function(data) {

      if (data !== null) {
        if ($el[0].hasAttribute('disabled')) {
          $el.removeAttr('disabled');
        }

        $.each(data, function(key,value) {
          // console.log(value);
          var newOption = $("<option></option>")
          .attr("value", value).text(value);

          $el.append(newOption);
        });

        if (stateSelected) {
          $el.find("option:textEquals('"+stateSelected+"')").prop('selected', true);
        }

      } else {

        $el.attr('disabled', 'disabled');

      }
    });
  }


}
