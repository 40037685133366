function ValidatePassword() {
  /* between 8 and 32 characters, 1 lower, 1 upper, one number, not use %<;'/\ */
  var rules = [{
      Pattern: "[A-Z]",
      Target: "UpperCase"
    },
    {
      Pattern: "[a-z]",
      Target: "LowerCase"
    },
    {
      Pattern: "[0-9]",
      Target: "Numbers"
    },
    {
      Pattern: "[!@@#$%^&*]",
      Target: "Symbols"
    }
  ];

  var password = $(this).val();
  console.log(password);

  /*Length Check for at least 8 characters */

  $("#Length").removeClass(password.length > 7 ? "glyphicon-remove" : "glyphicon-ok")
              .addClass(password.length > 7 ? "glyphicon-ok" : "glyphicon-remove");
  
  for (var i = 0; i < rules.length; i++) {

    $("#" + rules[i].Target).removeClass(new RegExp(rules[i].Pattern).test(password) ? "glyphicon-remove" : "glyphicon-ok"); 
    $("#" + rules[i].Target).addClass(new RegExp(rules[i].Pattern).test(password) ? "glyphicon-ok" : "glyphicon-remove");
      }
}