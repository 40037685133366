require("flatpickr");
import 'flatpickr/dist/flatpickr.min.css';

$(document).on('turbolinks:load', function() {
  $(".date-picker").flatpickr({
    altInput: true,
    minDate: "today",
    maxDate: new Date().fp_incr(14), // 14 days from now
  });
  
  $(".time-picker").flatpickr({
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i",
    minTime: "8:00",
    maxTime: "17:00",
  });
});