// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start();
// require("@rails/activestorage").start();
// require("channels");
// require("jquery");
// require("turbolinks").start();

// require("bootstrap");
require("@fortawesome/fontawesome-pro/js/all.min.js");

// require("datatables.net-bs4");
// require("bootstrap-datepicker");
// require('datatables');
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "jquery";
import "bootstrap";
import "datatables.net-bs4";
import "../active_button/button";
import "../admin/admin_portal";
import "../aspph_101_sub_nav/sub_nav";
import "../aspph_profile/aspph_profile";
import "../datatables/datatables";
import "../date_input/date_picker";
import "../engagement_groups/engagement";
import "../forms/country_state_form";
import "../forms/file_upload";
import "../forms/popover";
import "../forms/validate_password";
import "../governance_groups/governance";
import "../markdown/tinymce";
import "../member_benefits_tabs/tabs";
import "../my_institution/my_institution";
import "../my_profile/my_profile";
import "../nav/nav";
import "../on_scroll_effect/curtain";
// import "../prompts/prompts";
import "../site_content/site_content";
import "../sotl/display_sotl";
import "../supports/support_form";
// import "../load_more_button/scroll";
import tinymce from "tinymce/tinymce";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/content.min";
import "tinymce/skins/ui/oxide/skin.min";
import "../userway/userway";
// import "../validations/input_validations";
// import "../validations/input_validations_edit";

// require("bs-custom-file-input");



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
Rails.start();
Turbolinks.start();
ActiveStorage.start();
