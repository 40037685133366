$(document).on('turbolinks:load', function() {
    if ($('#benefit-cards-section').length) {
        tabOne();
        tabTwo();
        tabThree();
        tabFour();
        tabFive();
    }
});

function tabOne() {
    $('.tab-one').click(function (e) { 
    e.preventDefault();
    $('.partial').not(this).hide();
    $('.tab-1').show()
  });
}

function tabTwo() {
    $('.tab-two').click(function (e) { 
    e.preventDefault();
    $('.partial').not(this).hide();
    $('.tab-2').show()
  });
}

function tabThree() {
    $('.tab-three').click(function (e) { 
    e.preventDefault();
    $('.partial').not(this).hide();
    $('.tab-3').show()
  });
}

function tabFour() {
    $('.tab-four').click(function (e) { 
    e.preventDefault();
    $('.partial').not(this).hide();
    $('.tab-4').show()
  });
}

function tabFive() {
    $('.tab-five').click(function (e) { 
    e.preventDefault();
    $('.partial').not(this).hide();
    $('.tab-5').show()
  });
}
