$(document).on("turbolinks:load", function () {
  if ($(".engagement-groups-wheel").length) {
    engagementGroups();
    taskforces();
    sections();
    councils();
    assemblies();
  }
});

function engagementGroups() {
  $(".forums").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-0").show();
  });
}

function taskforces() {
  $(".taskforces").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-1").show();
  });
}

function sections() {
  $(".sections").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-3").show();
  });
}

function councils() {
  $(".councils").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-4").show();
  });
}

function assemblies() {
  $(".assemblies").click(function (e) {
    e.preventDefault();
    $(".partial").not(this).hide();
    $(".right-paragraph-5").show();
  });
}
