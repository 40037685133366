$(document).on('turbolinks:load', function() {
  addReviewerListener();
  editReviewerListener();
  assignReviewerListener();
  editReviewAssignmentListener();
  adminEditResourceListener();
});

var addReviewerListener = function () {
  $("body").on('click', "#add-reviewer", function(e){
    e.preventDefault();

    $("#reviewer-new-modal").modal("show");
    $("#reviewer-new-modal form")[0].reset();
    $("#reviewer-new-modal #error-messages").html("");

  });
}

var editReviewerListener = function() {
  // console.log('edit listener engaged');
  $('.reviewer-edit-link').click(function(e) {
    e.preventDefault();

    $('#reviewer-spinner-modal').modal('show');

    data = $(this).data();
    $.get('/admin/reviewers/'+data.user+'/edit')
  });
}

var assignReviewerListener = function () {
  $("body").on('click', "#assign-reviewer-button", function(e){
    e.preventDefault();

    $("#reviewer-assign-modal").modal("show");
    $("#reviewer-assign-modal form")[0].reset();
    $("#reviewer-assign-modal #error-messages").html("");

  });
}

var editReviewAssignmentListener = function () {
  $("body").on('click', ".review-assignment-edit-link", function(e){
    e.preventDefault();
    console.log('edit assignment clicked');
    $('#review-spinner-modal').modal('show');

    data = $(this).data();
    $.get('/admin/reviews/'+data.review+'/edit')

  });
}

var adminEditResourceListener = function () {
  $("body").on('click', "#finalize-review-button", function(e){
    e.preventDefault();
    console.log('edit assignment clicked');
    $('#resource-spinner-modal').modal('show');

    data = $(this).data();
    $.get('/admin/resources/'+data.resource+'/edit')

  });
}
