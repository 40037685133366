$(document).on("turbolinks:load", function () {
  if ($("#my-resources-page").length) {
    selectSoTL();
    selectSoTL1();
  }
});


function selectSoTL() {
  if ($("#addResourceModal-open").hasClass("add-resource-modal-in-search")) {
    $("#topic_area").change(function () {
        if ($("#topic_area").val() == "1") {
          $(".sotl-section").removeClass("d-none");
          $("#teaching-notes-label").addClass("required");
          $("#other-topic-area-section").addClass("d-none");
          $("#topic-area-other-input").val("");

        } else {
            if ($("#topic_area").val() == "4") {
              $("#other-topic-area-section").removeClass("d-none");
            } else {
              $("#other-topic-area-section").addClass("d-none");

            }


          // disable_sotl_required();
          $(".sotl-section").addClass("d-none");
          // console.log('disabled sotl fields?');
          $("#teaching-notes-label").removeClass("required");

        }
      });
    }
  }


function selectSoTL1() {
  if ($("#edit-resource-modal-open-").hasClass("edit-resource-modal-in-search-")) {
    if ($("#topic_area").val() == "1") {
      $(".sotl-section-1").removeClass("d-none");
      $("#teaching-notes-label").addClass("required");
    } else {
      if ($("#topic_area").val() == "4") {
        $("#other-topic-area-section").removeClass("d-none");
      }
    }

    $("#topic_area").change(function () {

      if ($("#topic_area").val() == "1") {
        $(".sotl-section-1").removeClass("d-none");
        $("#teaching-notes-label").addClass("required");
        $("#other-topic-area-section").addClass("d-none");
        $("#topic-area-other-input").val("");
      }
      else {
        if ($("#topic_area").val() == "4") {
          console.log('this is other');
          $("#other-topic-area-section").removeClass("d-none");
          $("#topic-area-other-input").addClass("required");
        } else {
            $("#topic-area-other-input").val("");
            $("#other-topic-area-section").addClass("d-none");
            $("#topic-area-other-input").removeClass("required");

        }

        $(".sotl-section-1").addClass("d-none");
        $("#teaching-notes-label").removeClass("required");
      }

    });
  }
}
