document.addEventListener('turbolinks:before-visit', () => {
  document.querySelector("html").removeAttribute("data-uw-w-loader");
  window._userway = false
});

['turbolinks:load', 'turbolinks:visit'].forEach((evt) => {
  document.addEventListener(evt, () => {
      (function(d) {
          var s = d.createElement("script");
          s.setAttribute("data-account", "szhkpohjIm");
          s.setAttribute("src", "https://cdn.userway.org/widget.js");
          s.setAttribute("data-position", 5);
          (d.body || d.head).appendChild(s);
      })(document)
  })
});