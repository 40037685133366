$.expr[':'].textEquals = function(el, i, m) {
      var searchText = m[3];
      var match = $(el).text().trim().match("^" + searchText + "$");
      return match && match.length > 0;
};

function profileAddCountrySelectListener() {
  $("#profile-container .profile-country-select").change(function() {
    profilePopulateCorrectStates(this);
  });
}

function profilePopulateCorrectStates(country) {
  // from selected country --> .col-sm-9 --> .form-group --> next .form-group (x3) --> find state-select
  var $el = $("#profile-container").find(".state-select");
  var countryName = $(country).val();
  var stateSelected = $el.val();


  if ($el.length > 0) {
    $el.empty();

    $.getJSON("/profile_states", { country_name: countryName }, function(data) {
      if (data !== null) {
        if ($el[0].hasAttribute('disabled')) {
          $el.removeAttr('disabled');
        }

        $.each(data, function(key,value) {
          // console.log(value);
          var newOption = $("<option></option>")
          .attr("value", value).text(value);

          $el.append(newOption);
        });

        if (stateSelected) {
          $el.find("option:textEquals('"+stateSelected+"')").prop('selected', true);
        }
      } else {
        $el.attr('disabled', 'disabled');
      }
    });
  }
}

// $(document).on('turbolinks:load', function() {
$(function() {
  profileAddCountrySelectListener();

  $("#profile-container .profile-country-select").change(function() {
    console.log('clicked');
    profilePopulateCorrectStates(this);
  });

  if ($("#profile-container .profile-country-select").length > 0) {
    var countrySelects = $(".profile-country-select");
    countrySelects.each(function () {
      profilePopulateCorrectStates(this);
    });
  }

  // Opt out stuff
  $("#profile-container #profile_opt_out").change(function() {
    $("#opt-out-alert").toggleClass("active", this.checked);
    $("#opt-in-alert").toggleClass("hidden", this.checked);
  });

});
