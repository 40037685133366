$(document).on('turbolinks:load', function() {
  $("#institution-contacts-table").DataTable({
    pageLength: 25,
    searching: true,
    order: [[ 3, 'asc'], [2, 'asc']],
    columnDefs: [
      { orderable: false, targets: [0, 1]}]
  });

  $("#institution-contacts-table-beta-2").DataTable({
    // dom: "ftip",
    pageLength: 25,
    searching: true,
    order: [[ 2, 'asc'], [1, 'asc']],
    columnDefs: [
      { orderable: false, targets: [0]}]
  });

  $("#admin-member-prompts-table").DataTable({
    pageLength: 25,
    searching: true,
    order: [ 4, 'desc'],
    columnDefs: [
      { orderable: false, targets: [5]}]

  });

  $("#data-products-table").DataTable({
    pageLength: 25,
    searching: true,
    order: [ 2, 'asc'],
    columnDefs: [
      { orderable: false, targets: [3]}]
  });
});
