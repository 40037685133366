$(document).on("turbolinks:load", function () {
  if ($("#homepage-section-two").length) {

    $(window).scroll(function () {
      if (elementInViewport2(".curtain__panel--left")) {
        $(".curtain__panel--left").css("transform", "translateX(-35%)");
        $(".curtain__panel--right").css("transform", "translateX(35%)");
        $(".curtain__panel--left").addClass("enabled");
        $(".curtain__panel--right").addClass("enabled");
      } else {
        $(".curtain__panel--left").css("transform", "translateX(0)");
        $(".curtain__panel--right").css("transform", "translateX(0)");
        $(".curtain__panel--left").removeClass("enabled");
        $(".curtain__panel--right").removeClass("enabled");
      }
    });

    $(window).scroll(function () {
      if (elementInViewport2(".curtain__panel--left-2")) {
        $(".curtain__panel--left-2").css("transform", "translateX(-35%)");
        $(".curtain__panel--right-2").css("transform", "translateX(35%)");
        $(".curtain__panel--left-2").addClass("enabled");
        $(".curtain__panel--right-2").addClass("enabled");
      } else {
        $(".curtain__panel--left-2").css("transform", "translateX(0)");
        $(".curtain__panel--right-2").css("transform", "translateX(0)");
        $(".curtain__panel--left-2").removeClass("enabled");
        $(".curtain__panel--right-2").removeClass("enabled");
      }
    });
  }
});

function elementInViewport2(elm, evalType) {
  evalType = evalType || "visible";

  var vpH = $(window).height(),
    st = $(window).scrollTop(),
    y = $(elm).offset().top,
    elementHeight = $(elm).height();

  if (evalType === "visible") return y < vpH + st && y > st - elementHeight;
  if (evalType === "above") return y < vpH + st;
}
